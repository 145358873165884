import request from '../../request'
import qs from 'qs'

//  在线作业 - 设置学期 - 查询
const QueryEduCourseWorkSetPlan = (p) => {
    return request.get(`eduCourseWorkSetTea/queryEduCourseWorkSetPlan?${qs.stringify(p)}`).then(res => res.data);
}
// 在线作业 - 设置学期 - 是否有效
const UpdateEduCourseWorkSetPlan = (data) => {
    return request.post(`eduCourseWorkSetTea/updateEduCourseWorkSetPlan`, data).then(res => res.data);
}
// 在线作业 - 设置学期 - 删除
const DeletedEduCourseWorkSetPlan = (p) => {
    return request.get(`eduCourseWorkSetTea/deletedEduCourseWorkSetPlan?${qs.stringify(p)}`, data).then(res => res.data);
}
// 在线作业 - 设置学期 - 修改时间
const UpdateEduCourseWorkSetPlanTime = (data) => {
    return request.post(`eduCourseWorkSetTea/updateEduCourseWorkSetPlanTime`, data).then(res => res.data);
}
// 在线作业 - 设置学期 - 添加 - 查询符合规定的教学计划
const QueryAllEduTeachPlanByTea = (p) => {
    return request.get(`eduCourseWorkSetTea/queryAllEduTeachPlanByTea?${qs.stringify(p)}`).then(res => res.data);
}
// 在线作业-设置学期-批量给教学计划添加在线作业（添加）
const AddCourseWorkSetPlanByCourseId = (data) => {
    return request.post(`eduCourseWorkSetTea/addCourseWorkSetPlanByCourseId`, data).then(res => res.data);
}
// 在线作业 - 查看
const QueryStudentWork = (p) => {
    return request.get(`eduCourseWorkSetTea/queryStudentWork?${qs.stringify(p)}`).then(res => res.data)
}
// 在线作业 - 查看学生提交作业记录及系统批阅情况 
const SelectStuWorkRecordByChecked = (p) => {
    return request.get(`eduCourseWorkSetTea/selectEduCourseStudentWorkRecordByStudentIdAndWorkId?${qs.stringify(p)}`).then(res => res.data)
}
// -----在线考试

// 在线考试 - 查询全部在线考试 
const QueryEduCourseExamSetAll = (p) => {
    return request.get(`eduCourseExamSet/queryEduCourseExamSetAll?${qs.stringify(p)}`).then(res => res.data)
}
// 在线考试 - 更改考试时间和更改所有考试时间
const UpdateEduCourseExamSet = (data) => {
    return request.post(`eduCourseExamSet/updateEduCourseExamSet`, data).then(res => res.data)
}
// 在线考试 - 编辑
const EduCourseExamSetEdit = (data) => {
    return request.post(`eduCourseExamSet/edit`, data).then(res => res.data)
}
// 在线作业 - 查询 
const QueryAllCourseWorkSetByCourseIdByTea = () => {
    return request.get(`eduCourseWorkSetTea/queryAllCourseWorkSetByCourseIdByTea`).then(res => res.data)
}
// 在线作业 - 编辑前查询 
const QueryEduCourseExamSetById = (id) => {
    return request.get(`eduCourseExamSet/queryEduCourseExamSetById/${id}`).then(res => res.data)
}
// 首页菜单 - 在线答疑 查询答疑【未回复（最新提醒与未回复 只看学生的答疑）】
const QueryAllEduAnswering = (data) => {
    return request.post(`eduAnswering/queryAllEduAnswering`, data).then(res => res.data)
}
// 查询当前教师在授课程
const QueryAllCourseWorkSetByCourseIdByTeaId = () => {
    return request.get(`eduCourseWorkSetTea/queryAllCourseWorkSetByCourseIdByTeaId`).then(res => res.data)
}
// 首页菜单 - 在线答疑 - 详情
const QueryRecursionAllEduAnswering = (p) => {
    return request.get(`eduAnswering/queryRecursionAllEduAnswering?${qs.stringify(p)}`).then(res => res.data)
}
// 首页菜单 - 在线答疑 - 点赞
const FabulousEduAnsweringByIdAndTea = (data) => {
    return request.post(`eduAnwFabulous/fabulousEduAnsweringByIdAndTea`, data).then(res => res.data)
}
export {
    QueryEduCourseWorkSetPlan,
    UpdateEduCourseWorkSetPlan,
    DeletedEduCourseWorkSetPlan,
    UpdateEduCourseWorkSetPlanTime,
    QueryAllEduTeachPlanByTea,
    AddCourseWorkSetPlanByCourseId,
    QueryStudentWork,
    SelectStuWorkRecordByChecked,
    QueryEduCourseExamSetAll,
    UpdateEduCourseExamSet,
    EduCourseExamSetEdit,
    QueryAllCourseWorkSetByCourseIdByTea,
    QueryEduCourseExamSetById,
    QueryAllEduAnswering,
    QueryAllCourseWorkSetByCourseIdByTeaId,
    QueryRecursionAllEduAnswering,
    FabulousEduAnsweringByIdAndTea,
}