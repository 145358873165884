<template>
  <div class="Teacher-Exam">
    <div class="stu-module-header">
      <div class="stu-module-title">在线考试</div>
      <div v-if="queryRoot">
        <el-button @click="checkAll" type="primary">{{
          cancelAll ? "全不选" : "全选"
        }}</el-button>
        <el-button @click="updateExamTime" type="primary">
          更改考试时间
        </el-button>
      </div>
    </div>
    <el-collapse
      v-model="activeNames"
      v-if="queryRoot"
      v-loading="loading"
      class="content"
    >
      <el-collapse-item
        class="items"
        v-for="(item, index) in courseData"
        :key="index"
        :name="index"
      >
        <template slot="title">
          <div class="content_header">
            <p>课程</p>
            <p>
              {{ item.courseName }}
            </p>
          </div>
        </template>
        <el-table :data="item.data" :ref="`examTable_${index}`">
          <el-table-column align="center" width="70">
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.checked"
                @change="checkboxChange(scope.row.id)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="序号"
            type="index"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            v-for="(col, i) in columns"
            :key="i"
            :prop="col.prop"
            :label="col.label"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            label="操作"
            width="140"
            v-if="seeRoot || updateRoot"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="edit(scope.row)" v-if="updateRoot">
                编辑
              </el-button>
              <el-button type="text" @click="details(scope.row)" v-if="seeRoot">
                预览
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>
    <no-root v-else></no-root>
    <el-dialog :visible.sync="dialog" title="提示" width="30%">
      <ul class="updateExamTime_ul">
        <li>1.2020年上学期英语考试</li>
        <li>2.2020级上半学期考试</li>
        <li>3.2020级下半学期考试</li>
        <li>4.7月考试</li>
      </ul>
      <el-form :model="form" label-width="100px">
        <el-form-item label="考试开始时间">
          <el-date-picker
            v-model="form.answerStartTime"
            type="datetime"
            placeholder="选择日期时间"
            format="yyyy/MM/dd HH:mm:ss"
            value-format="yyyy/MM/dd HH:mm:ss"
            align="right"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="考试结束时间">
          <el-date-picker
            v-model="form.answerEndTime"
            type="datetime"
            format="yyyy/MM/dd HH:mm:ss"
            value-format="yyyy/MM/dd HH:mm:ss"
            placeholder="选择日期时间"
            align="right"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  QueryEduCourseExamSetAll,
  UpdateEduCourseExamSet,
} from "@/libs/api/teacher/online";
export default {
  name: "teacher-Exam",
  components: {},
  data() {
    return {
      loading: false,
      activeNames: [],
      courseData: [],
      cancelAll: false,
      columns: [
        { label: "考试名称", prop: "examName" },
        { label: "描述", prop: "answerDesc" },
        { label: "开始时间", prop: "answerStartTime" },
        { label: "结束时间", prop: "answerEndTime" },
      ],
      selectionSelect: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "明天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周后",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      form: {
        answerStartTime: null,
        answerEndTime: null,
      },
      dialog: false,
    };
  },
  computed: {
    queryRoot() {
      return this.$sysPerKey("eduTeacherExam:list");
    },
    updateRoot() {
      return this.$sysPerKey("eduTeacherExam:update");
    },
    seeRoot() {
      return this.$sysPerKey("eduTeacherExam:seeExam");
    },
  },
  watch: {
    queryRoot(n) {
      n && this.query();
    },
  },
  mounted() {
    this.queryRoot && this.query();
  },
  methods: {
    query() {
      this.loading = true;
      QueryEduCourseExamSetAll()
        .then((res) => {
          this.loading = false;
          this.courseData = [];
          res.data.forEach((item) => {
            this.courseData.push({
              courseName: item.courseName,
              data: [{ ...item, checked: false }],
            });
          });
          this.activeNames = this.courseData.map((f, i) => i);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    edit(row) {
      if (row) {
        this.$router.push({
          path: "exam/edit",
          query: {
            id: row.id,
          },
        });
      }
    },
    details(row) {
      this.$router.push({
        path: "exam/preview",
        name: "exam-preview",
        query: {
          id: row.id,
        },
      });
    },
    checkboxChange(val) {
      const list = this.selectionSelect;
      if (!list.includes(val)) {
        this.selectionSelect.push(val);
      } else {
        const index = list.findIndex((f) => f === val);
        this.selectionSelect.splice(index, 1);
      }
    },
    checkAll() {
      this.selectionSelect = [];
      this.cancelAll = !this.cancelAll;
      this.courseData.forEach((item) => {
        if (this.cancelAll)
          item.data.forEach((v) => {
            v.checked = true;
            this.selectionSelect.push(v.id);
          });
        else {
          this.selectionSelect = [];
          item.data.forEach((v) => {
            v.checked = false;
          });
        }
      });
    },
    updateExamTime() {
      if (this.selectionSelect.length === 0)
        return this.$message.error("请选择考试");
      this.dialog = true;
    },

    confirm() {
      const data = {
        ids: this.selectionSelect.join(),
        ...this.form,
      };
      UpdateEduCourseExamSet(data).then((res) => {
        this.$message.success("成功更改考试时间");
        this.query();
        this.dialog = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.Teacher-Exam {
  background: #fff;
  box-shadow: @shadow;
  .stu-module-header {
    border-bottom: 1px solid @borderBottomColor;
    padding: 20px;
  }
  .content {
    padding: 20px;
    min-height: 100px;
    .items {
      .content_header {
        display: flex;
        align-items: center;
        p:first-child {
          background: @mainColor;
          color: #fff;
          width: 60px;
          text-align: center;
          margin-right: 10px;
        }
      }

      .el-table {
        min-height: unset;
      }
    }
  }
  .updateExamTime_ul {
    margin-bottom: 15px;
    li {
      margin-bottom: 10px;
    }
  }
}
</style>